import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import packageJson from "../package.json";
import { getBuildDate } from "./utils/utils";
import withClearCache from "./ClearCache";



// Preloader
import Preloader from './components/layouts/Preloader';

import Home from './components/pages/Home';
import Speisekarte from './components/pages/Speisekarte';
import Kontakt from './components/pages/Kontakt';
import NotFound from './components/pages/NotFound';

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  return (
    <Router>
      <Preloader/>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/speisekarte" component={Speisekarte} />
        <Route path="/kontakt" component={Kontakt} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}



export default App;
