import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MDBCol, MDBContainer, MDBRow, MDBFooter} from "mdbreact";

class Footer extends Component {
    render() {
        return (
            <MDBFooter color="gp-green" className="font-small pt-4 mt-4">
                <MDBContainer className="text-center ">
                    <MDBRow>
                        <MDBCol md="12">
                            <p>
                             Seestrasse 41, 7310 Bad Ragaz | <a href="tel:+41816612345"> 081 661 23 45</a>
                            </p>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <div className="gp-black footer-copyright text-center py-3">
                    <MDBContainer fluid>
                        <a href="https://www.giessenpark.com/" target="_blank"> Giessenpark GmbH </a> &copy; {new Date().getFullYear()}. Alle Rechte vorbehalten <span>|</span> <a href="http://www.zenzvio.ch/" target="_blank"> Webagentur </a>
                    </MDBContainer>
                </div>
            </MDBFooter>
        );
    }
}

export default Footer;