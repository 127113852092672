import React, { Component, Fragment } from 'react';
import {Helmet} from "react-helmet";
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBContainer, MDBMask, MDBView,MDBRow, MDBCol } from 'mdbreact';
import MediaQuery from 'react-responsive';

import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';


import headerImage from '../assets/img/oops.jpg';
import headerImageMob from '../assets/img/oops_mob.jpg';

import notFoundImage1 from '../assets/img/404_1.jpg';
import notFoundImage1Mob from '../assets/img/404_1.jpg';

import notFoundImage2 from '../assets/img/404_2.jpg';
import notFoundImage2Mob from '../assets/img/404_2.jpg';




import image1 from '../assets/img/giessenpark_takeaway_kontakt.jpg';
import image1Mob from '../assets/img/giessenpark_takeaway_kontakt_mob.jpg';

class NotFound extends Component {
    render() {
        return (
            <Fragment>
                <MediaQuery maxWidth={760}>
                    <Header headerImage={headerImageMob} />
                </MediaQuery>
                <MediaQuery minWidth={760}> 
                    <Header headerImage={headerImage} />
                </MediaQuery>
                <Content/>
                <Footer />
            </Fragment>
        );
    }
}

const Content = () => (

    <main>

        <Helmet>
            <link rel="canonical" href="https://foodboxragaz.ch/" />
            <title>Kontakt - Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310</title>
            <meta charset="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
            <meta name="theme-color" content="#69a73a"/>
            <meta name="author" content="Giessenpark"/>
            <meta name="publisher" content="www.zenzvio.ch"/>
            <meta name="copyright" content="Giessenpark GmbH"/>
            <meta name="description" content="Takeaway und Lieferung Bad Ragaz 7310 | Lieferung Bad Ragaz 7310 | Takeaway Bad Ragaz 7310 | Essen bestellen Bad Ragaz 7310 | Europäische Gerichte  | Asiatische Speisen | Essen Bad Ragaz 7310 | Essen | Indische Küche Bad Ragaz 7310 | Indische Essen Bad Ragaz 7310 | Indische Küche | Indische Essen | Order takeaway food in Bad Ragaz | Order Bad Ragaz | Indian Food Bad Ragaz"/>
            <meta name="keywords" content="Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310, Erleben Sie authentische indische Küche und Tandoor Spezialitäten mit Liebe zubereitet. Geschmack bieten sich europäische Gerichte und auch asiatische Speisen. Gehen Sie direkt zu unserem Online-Menü und Bestellen. Sie bekommen es in kürzester Zeit an Ihre Tür geliefert"/>
            <meta NAME="classification" content="foodboxragaz.ch | Bad Ragaz | Giessenpark"/>
            <meta NAME="revisit-after" CONTENT="2 days"/>
            <meta NAME="revisit" CONTENT="After 2 days"/>
            <meta name="page-topic" content="Essen bestellen, Takeaway und Lieferung"/>
            <meta name="page-type" content="Restaurant Food"/>
            <meta name="audience" content="Alle"/>
            <meta http-equiv="content-language" content="de"/>
            <meta name="robots" content="index, follow"/>
            <meta http-equiv="ImageToolbar" content="false"/>
            <meta http-equiv="ClearType" content="true"/>
            <meta name="MSSmartTagsPreventParsing" content="true"/>
            <meta name="DC.Creator" content="Giessenpark"/>
            <meta name="DC.Publisher" content="www.zenzvio.ch"/>
            <meta name="DC.Rights" content="Giessenpark GmbH"/>
            <meta name="DC.Description" content="Takeaway und Lieferung Bad Ragaz 7310 | Lieferung Bad Ragaz 7310 | Takeaway Bad Ragaz 7310 | Essen bestellen Bad Ragaz 7310 | Europäische Gerichte  | Asiatische Speisen | Essen Bad Ragaz 7310 | Essen | Indische Küche Bad Ragaz 7310 | Indische Essen Bad Ragaz 7310 | Indische Küche | Indische Essen | Order takeaway food in Bad Ragaz | Order Bad Ragaz | Indian Food Bad Ragaz"/>
            <meta name="dc.source" CONTENT=""/>
            <meta name="dc.title" CONTENT="Kontakt - Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310"/>
            <meta name="DC.Language" content="de"/>
            <meta property="og:locale" content="de_DE" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Kontakt - Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310" />
            <meta property="og:description" content="Takeaway und Lieferung Bad Ragaz 7310 | Lieferung Bad Ragaz 7310 | Takeaway Bad Ragaz 7310 | Essen bestellen Bad Ragaz 7310 | Europäische Gerichte  | Asiatische Speisen | Essen Bad Ragaz 7310 | Essen | Indische Küche Bad Ragaz 7310 | Indische Essen Bad Ragaz 7310 | Indische Küche | Indische Essen | Order takeaway food in Bad Ragaz | Order Bad Ragaz | Indian Food Bad Ragaz" />
            <meta property="og:url" content="https://www.foodboxragaz.ch/" />
            <meta property="og:site_name" content="foodboxragaz.ch - Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310" />
            <meta property="og:image" content="https://www.foodboxragaz.ch/foodboxragaz.jpg" />
        </Helmet>

        <section className="section-block-orange">
                <MDBContainer className="image-container not-found-page padding-left-right">
                    <MDBRow>
                        <h3>
                            404 Seite nicht verfügbar
                        </h3>
                    
                    </MDBRow>

                    <MDBRow className="holiday-image-block">   
                        <MDBCol xs="12" sm="12" lg="6" md="6" className="image-block">
                            <MDBView hover rounded className="z-depth-1-half mb-4">
                            <MediaQuery maxWidth={760}>
                                <img
                                    className="img-fluid"
                                    src={notFoundImage1Mob}
                                    alt=""
                                />
                            </MediaQuery>
                            <MediaQuery minWidth={760}> 
                                <img
                                    className="img-fluid"
                                    src={notFoundImage1}
                                    alt=""
                                />
                            </MediaQuery> 
                            </MDBView>
                        </MDBCol>
                        <MDBCol xs="12" sm="12" lg="6" md="6" className="image-block">
                            <MDBView hover rounded className="z-depth-1-half mb-4">
                            <MediaQuery maxWidth={760}>
                                <img
                                    className="img-fluid"
                                    src={notFoundImage2Mob}
                                    alt=""
                                />
                            </MediaQuery>
                            <MediaQuery minWidth={760}> 
                                <img
                                    className="img-fluid"
                                    src={notFoundImage2}
                                    alt=""
                                />
                            </MediaQuery> 
                            </MDBView>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </section>


    </main>

);

export default NotFound;