import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBContainer, MDBMask, MDBView, MDBIcon, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact';

import logo from '../assets/img/foodboxragaz_logo.png';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
          collapse: false,
          isWideEnough: false,
        };
        this.onClick = this.onClick.bind(this);
    }
    
    onClick() {
        this.setState({
            collapse: !this.state.collapse,
        });
    }

    render() {
      
        return (
            <header>
                    <MDBNavbar color="gp-green" dark expand="md" fixed="top">
                        <MDBNavbarBrand href="/">
                        <img src={logo} className="img-responsive logo" alt="foodboxragaz.ch Logo" />
                            
                        </MDBNavbarBrand>
                        {!this.state.isWideEnough && <MDBNavbarToggler onClick={this.onClick} />}
                        <MDBCollapse isOpen={this.state.collapse} navbar>
                            <MDBNavbarNav left>
                                <MDBNavItem>
                                    <MDBNavLink to="/" activeClassName="active" exact>Home</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink to="/speisekarte" activeClassName="active" >Speisekarte</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBNavLink to="/kontakt" activeClassName="active" >Kontakt</MDBNavLink>
                                </MDBNavItem>
                            </MDBNavbarNav>
                        </MDBCollapse>
                    </MDBNavbar>
                <MDBView src={this.props.headerImage}></MDBView>
            </header>
        );
    }
}

export default Header;
