import React, { Component, Fragment } from 'react';
import {Helmet} from "react-helmet";
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBContainer, MDBMask, MDBView,MDBRow, MDBCol } from 'mdbreact';
import MediaQuery from 'react-responsive';

import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';


import headerImage from '../assets/img/GP_slider_5.jpg';
import headerImageMob from '../assets/img/GP_slider_5_mob.jpg';

import image1 from '../assets/menu_img/Foodbox_Kontakt-1.jpg';
import image1Mob from '../assets/menu_img/Foodbox_Kontakt-1_mob.jpg';

class Kontakt extends Component {
    render() {
        return (
            <Fragment>
                <MediaQuery maxWidth={760}>
                    <Header headerImage={headerImageMob} />
                </MediaQuery>
                <MediaQuery minWidth={760}> 
                    <Header headerImage={headerImage} />
                </MediaQuery>
                <Content/>
                <Footer />
            </Fragment>
        );
    }
}

const Content = () => (

    <main>

        <Helmet>
            <link rel="canonical" href="https://foodboxragaz.ch/" />
            <title>Kontakt - Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310</title>
            <meta charset="utf-8"/>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
            <meta name="theme-color" content="#69a73a"/>
            <meta name="author" content="Giessenpark"/>
            <meta name="publisher" content="www.zenzvio.ch"/>
            <meta name="copyright" content="Giessenpark GmbH"/>
            <meta name="description" content="Takeaway und Lieferung Bad Ragaz 7310 | Lieferung Bad Ragaz 7310 | Takeaway Bad Ragaz 7310 | Essen bestellen Bad Ragaz 7310 | Europäische Gerichte  | Asiatische Speisen | Essen Bad Ragaz 7310 | Essen | Indische Küche Bad Ragaz 7310 | Indische Essen Bad Ragaz 7310 | Indische Küche | Indische Essen | Order takeaway food in Bad Ragaz | Order Bad Ragaz | Indian Food Bad Ragaz"/>
            <meta name="keywords" content="Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310, Erleben Sie authentische indische Küche und Tandoor Spezialitäten mit Liebe zubereitet. Geschmack bieten sich europäische Gerichte und auch asiatische Speisen. Gehen Sie direkt zu unserem Online-Menü und Bestellen. Sie bekommen es in kürzester Zeit an Ihre Tür geliefert"/>
            <meta NAME="classification" content="foodboxragaz.ch | Bad Ragaz | Giessenpark"/>
            <meta NAME="revisit-after" CONTENT="2 days"/>
            <meta NAME="revisit" CONTENT="After 2 days"/>
            <meta name="page-topic" content="Essen bestellen, Takeaway und Lieferung"/>
            <meta name="page-type" content="Restaurant Food"/>
            <meta name="audience" content="Alle"/>
            <meta http-equiv="content-language" content="de"/>
            <meta name="robots" content="index, follow"/>
            <meta http-equiv="ImageToolbar" content="false"/>
            <meta http-equiv="ClearType" content="true"/>
            <meta name="MSSmartTagsPreventParsing" content="true"/>
            <meta name="DC.Creator" content="Giessenpark"/>
            <meta name="DC.Publisher" content="www.zenzvio.ch"/>
            <meta name="DC.Rights" content="Giessenpark GmbH"/>
            <meta name="DC.Description" content="Takeaway und Lieferung Bad Ragaz 7310 | Lieferung Bad Ragaz 7310 | Takeaway Bad Ragaz 7310 | Essen bestellen Bad Ragaz 7310 | Europäische Gerichte  | Asiatische Speisen | Essen Bad Ragaz 7310 | Essen | Indische Küche Bad Ragaz 7310 | Indische Essen Bad Ragaz 7310 | Indische Küche | Indische Essen | Order takeaway food in Bad Ragaz | Order Bad Ragaz | Indian Food Bad Ragaz"/>
            <meta name="dc.source" CONTENT=""/>
            <meta name="dc.title" CONTENT="Kontakt - Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310"/>
            <meta name="DC.Language" content="de"/>
            <meta property="og:locale" content="de_DE" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Kontakt - Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310" />
            <meta property="og:description" content="Takeaway und Lieferung Bad Ragaz 7310 | Lieferung Bad Ragaz 7310 | Takeaway Bad Ragaz 7310 | Essen bestellen Bad Ragaz 7310 | Europäische Gerichte  | Asiatische Speisen | Essen Bad Ragaz 7310 | Essen | Indische Küche Bad Ragaz 7310 | Indische Essen Bad Ragaz 7310 | Indische Küche | Indische Essen | Order takeaway food in Bad Ragaz | Order Bad Ragaz | Indian Food Bad Ragaz" />
            <meta property="og:url" content="https://www.foodboxragaz.ch/" />
            <meta property="og:site_name" content="foodboxragaz.ch - Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310" />
            <meta property="og:image" content="https://www.foodboxragaz.ch/foodboxragaz.jpg" />
        </Helmet>

            <section className="my-5">
              <MDBRow>
                <MDBCol>
                  <div
                    id="map-container"
                    className="rounded map-container"
                    style={{ height: "400px" }}
                  >
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d512.3812617909275!2d9.510671679075767!3d47.00591709928869!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4784cb854805c583%3A0x9e0d0c9199b76134!2sGiessenpark!5e0!3m2!1sen!2sch!4v1611096578858!5m2!1sen!2sch"
                      title="This is a unique title"
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      style={{ border: 0 }}
                    />
                  </div>
                  <br />  
                </MDBCol>
              </MDBRow>
            </section>



        <MDBContainer className="image-container">
            <MDBRow>
                <MDBCol md="12" className="mb-3">
                    <MediaQuery maxWidth={760}>
                        <img src={image1Mob} className="img-fluid z-depth-1" alt="Takeaway und Lieferung Bad Ragaz 7310" />
                    </MediaQuery>
                    <MediaQuery minWidth={760}> 
                        <img src={image1} className="img-fluid z-depth-1" alt="Takeaway und Lieferung Bad Ragaz 7310" />
                    </MediaQuery>
                </MDBCol>
            </MDBRow>

        </MDBContainer>
    </main>

);

export default Kontakt;