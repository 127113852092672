import React, { Component, Fragment } from 'react';
import {Helmet} from "react-helmet";
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBContainer, MDBMask, MDBView,MDBRow, MDBCol } from 'mdbreact';
import MediaQuery from 'react-responsive';

import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';


import headerImage from '../assets/img/GP_slider_1.jpg';
import headerImageMob from '../assets/img/GP_slider_1_mob.jpg';

import image1 from '../assets/menu_img/Foodbox_Deckblatt_NEU-1.jpg';
import image1Mob from '../assets/menu_img/Foodbox_Deckblatt_NEU-1_mob.jpg';

class Home extends Component {
    render() {
        return (
            <Fragment>
                <MediaQuery maxWidth={760}>
                    <Header headerImage={headerImageMob} />
                </MediaQuery>
                <MediaQuery minWidth={760}> 
                    <Header headerImage={headerImage} />
                </MediaQuery> 
                <Content/>
                <Footer />
            </Fragment>
        );
    }
}

const Content = () => (

        <main>

            <Helmet>
                <link rel="canonical" href="https://foodboxragaz.ch/" />
                <title>Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310 - foodboxragaz.ch</title>
                <meta charset="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
                <meta name="theme-color" content="#69a73a"/>
                <meta name="author" content="Giessenpark"/>
                <meta name="publisher" content="www.zenzvio.ch"/>
                <meta name="copyright" content="Giessenpark GmbH"/>
                <meta name="description" content="Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310, Erleben Sie authentische indische Küche und Tandoor Spezialitäten mit Liebe zubereitet. Geschmack bieten sich europäische Gerichte und auch asiatische Speisen. Gehen Sie direkt zu unserem Online-Menü und Bestellen. Sie bekommen es in kürzester Zeit an Ihre Tür geliefert"/>
                <meta name="keywords" content="Takeaway und Lieferung Bad Ragaz 7310 | Lieferung Bad Ragaz 7310 | Takeaway Bad Ragaz 7310 | Essen bestellen Bad Ragaz 7310 | Europäische Gerichte  | Asiatische Speisen | Essen Bad Ragaz 7310 | Essen | Indische Küche Bad Ragaz 7310 | Indische Essen Bad Ragaz 7310 | Indische Küche | Indische Essen | Order takeaway food in Bad Ragaz | Order Bad Ragaz | Indian Food Bad Ragaz"/>
                <meta NAME="classification" content="foodboxragaz.ch | Bad Ragaz | Giessenpark"/>
                <meta NAME="revisit-after" CONTENT="2 days"/>
                <meta NAME="revisit" CONTENT="After 2 days"/>
                <meta name="page-topic" content="Essen bestellen, Takeaway und Lieferung"/>
                <meta name="page-type" content="Restaurant Food"/>
                <meta name="audience" content="Alle"/>
                <meta http-equiv="content-language" content="de"/>
                <meta name="robots" content="index, follow"/>
                <meta http-equiv="ImageToolbar" content="false"/>
                <meta http-equiv="ClearType" content="true"/>
                <meta name="MSSmartTagsPreventParsing" content="true"/>
                <meta name="DC.Creator" content="Giessenpark"/>
                <meta name="DC.Publisher" content="www.zenzvio.ch"/>
                <meta name="DC.Rights" content="Giessenpark GmbH"/>
                <meta name="DC.Description" content="Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310, Erleben Sie authentische indische Küche und Tandoor Spezialitäten mit Liebe zubereitet. Geschmack bieten sich europäische Gerichte und auch asiatische Speisen. Gehen Sie direkt zu unserem Online-Menü und Bestellen. Sie bekommen es in kürzester Zeit an Ihre Tür geliefert"/>
                <meta name="dc.source" CONTENT=""/>
                <meta name="dc.title" CONTENT="Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310 - foodboxragaz.ch"/>
                <meta name="DC.Language" content="de"/>
                <meta property="og:locale" content="de_DE" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310 - foodboxragaz.ch" />
                <meta property="og:description" content="Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310, Erleben Sie authentische indische Küche und Tandoor Spezialitäten mit Liebe zubereitet. Geschmack bieten sich europäische Gerichte und auch asiatische Speisen. Gehen Sie direkt zu unserem Online-Menü und Bestellen. Sie bekommen es in kürzester Zeit an Ihre Tür geliefert" />
                <meta property="og:url" content="https://www.foodboxragaz.ch/" />
                <meta property="og:site_name" content="foodboxragaz.ch - Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310" />
                <meta property="og:image" content="https://www.foodboxragaz.ch/foodboxragaz.jpg" />
            </Helmet>

            <MDBContainer className="image-container">
                <MDBRow>
                    <MDBCol md="12" className="mb-3">
                        <MediaQuery maxWidth={760}>
                            <img src={image1Mob} className="img-fluid z-depth-1" alt="Indische Essen Bad Ragaz 7310" />
                        </MediaQuery>
                        <MediaQuery minWidth={760}> 
                            <img src={image1} className="img-fluid z-depth-1" alt="Order takeaway food in Bad Ragaz" />
                        </MediaQuery>  
                    </MDBCol>
                </MDBRow>

            </MDBContainer>
        </main>
        
);

export default Home;