import React, { Component, Fragment } from 'react';
import {Helmet} from "react-helmet";
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBContainer, MDBMask, MDBView,MDBRow, MDBCol } from 'mdbreact';
import MediaQuery from 'react-responsive';

import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';


import headerImage from '../assets/menu_img/Giessenpark_Speisekarte_Winter22_23_Page_01.jpg';
import headerImageMob from '../assets/menu_img/Giessenpark_Speisekarte_Winter22_23_Page_01_mob.jpg';




// import menuImage1 from '../assets/menu_img/Giessenpark_Speisekarte_Winter22_23_Page_01.jpg';
// import menuImage1Mob from '../assets/menu_img/Giessenpark_Speisekarte_Winter22_23_Page_01_mob.jpg';

import menuImage1 from '../assets/menu_img/menu_29_04_2023/1.jpg';
import menuImage1Mob from '../assets/menu_img/menu_29_04_2023/1.jpg';

import menuImage2 from '../assets/menu_img/menu_29_04_2023/2.jpg';
import menuImage2Mob from '../assets/menu_img/menu_29_04_2023/2.jpg';

import menuImage3 from '../assets/menu_img/menu_29_04_2023/3.jpg';
import menuImage3Mob from '../assets/menu_img/menu_29_04_2023/3.jpg';

import menuImage4 from '../assets/menu_img/menu_29_04_2023/4.jpg';
import menuImage4Mob from '../assets/menu_img/menu_29_04_2023/4.jpg';

import menuImage5 from '../assets/menu_img/menu_29_04_2023/5.jpg';
import menuImage5Mob from '../assets/menu_img/menu_29_04_2023/5.jpg';




import menuImage6 from '../assets/menu_img/Giessenpark_Speisekarte_Winter22_23_Page_06.jpg';
import menuImage6Mob from '../assets/menu_img/Giessenpark_Speisekarte_Winter22_23_Page_06_mob.jpg';

import menuImage7 from '../assets/menu_img/Giessenpark_Speisekarte_Winter22_23_Page_07.jpg';
import menuImage7Mob from '../assets/menu_img/Giessenpark_Speisekarte_Winter22_23_Page_07_mob.jpg';

import menuImage8 from '../assets/menu_img/Giessenpark_Speisekarte_Winter22_23_Page_08.jpg';
import menuImage8Mob from '../assets/menu_img/Giessenpark_Speisekarte_Winter22_23_Page_08_mob.jpg';

import menuImage9 from '../assets/menu_img/Giessenpark_Speisekarte_Winter22_23_Page_09.jpg';
import menuImage9Mob from '../assets/menu_img/Giessenpark_Speisekarte_Winter22_23_Page_09_mob.jpg';

import menuImage10 from '../assets/menu_img/Giessenpark_Speisekarte_Winter22_23_Page_10.jpg';
import menuImage10Mob from '../assets/menu_img/Giessenpark_Speisekarte_Winter22_23_Page_10_mob.jpg';

import menuImage11 from '../assets/menu_img/Giessenpark_Speisekarte_Winter22_23_Page_11.jpg';
import menuImage11Mob from '../assets/menu_img/Giessenpark_Speisekarte_Winter22_23_Page_11_mob.jpg';




import image10 from '../assets/img/giessenpark_takeaway_speisekarte_10.jpg'; // Easter Menu
import image10Mob from '../assets/img/giessenpark_takeaway_speisekarte_10_mob.jpg'; // Easter Menu
import image11 from '../assets/img/giessenpark_takeaway_speisekarte_11.jpg'; // Easter Menu
import image11Mob from '../assets/img/giessenpark_takeaway_speisekarte_11_mob.jpg'; // Easter Menu




class Speisekarte extends Component {
    render() {
        return (
            <Fragment>
                    <MediaQuery maxWidth={760}>
                        <Header headerImage={headerImageMob} />
                    </MediaQuery>
                    <MediaQuery minWidth={760}> 
                        <Header headerImage={headerImage} />
                    </MediaQuery> 
                <Content/>
                <Footer />
            </Fragment>
        );
    }
}


const Content = () => {

    const now = new Date();
    const goodFriday = new Date("april 03, 2021");
    const easter = new Date("april 05, 2021");
    
    return (

        <main>

                <Helmet>
                    <link rel="canonical" href="https://foodboxragaz.ch/" />
                    <title>Speisekarte - Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310</title>
                    <meta charset="utf-8"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
                    <meta name="theme-color" content="#69a73a"/>
                    <meta name="author" content="Giessenpark"/>
                    <meta name="publisher" content="www.zenzvio.ch"/>
                    <meta name="copyright" content="Giessenpark GmbH"/>
                    <meta name="description" content="Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310, Erleben Sie authentische indische Küche und Tandoor Spezialitäten mit Liebe zubereitet. Geschmack bieten sich europäische Gerichte und auch asiatische Speisen. Gehen Sie direkt zu unserem Online-Menü und Bestellen. Sie bekommen es in kürzester Zeit an Ihre Tür geliefert"/>
                    <meta name="keywords" content="Takeaway und Lieferung Bad Ragaz 7310 | Lieferung Bad Ragaz 7310 | Takeaway Bad Ragaz 7310 | Essen bestellen Bad Ragaz 7310 | Europäische Gerichte  | Asiatische Speisen | Essen Bad Ragaz 7310 | Essen | Indische Küche Bad Ragaz 7310 | Indische Essen Bad Ragaz 7310 | Indische Küche | Indische Essen | Order takeaway food in Bad Ragaz | Order Bad Ragaz | Indian Food Bad Ragaz"/>
                    <meta NAME="classification" content="foodboxragaz.ch | Bad Ragaz | Giessenpark"/>
                    <meta NAME="revisit-after" CONTENT="2 days"/>
                    <meta NAME="revisit" CONTENT="After 2 days"/>
                    <meta name="page-topic" content="Essen bestellen, Takeaway und Lieferung"/>
                    <meta name="page-type" content="Restaurant Food"/>
                    <meta name="audience" content="Alle"/>
                    <meta http-equiv="content-language" content="de"/>
                    <meta name="robots" content="index, follow"/>
                    <meta http-equiv="ImageToolbar" content="false"/>
                    <meta http-equiv="ClearType" content="true"/>
                    <meta name="MSSmartTagsPreventParsing" content="true"/>
                    <meta name="DC.Creator" content="Giessenpark"/>
                    <meta name="DC.Publisher" content="www.zenzvio.ch"/>
                    <meta name="DC.Rights" content="Giessenpark GmbH"/>
                    <meta name="DC.Description" content="Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310, Erleben Sie authentische indische Küche und Tandoor Spezialitäten mit Liebe zubereitet. Geschmack bieten sich europäische Gerichte und auch asiatische Speisen. Gehen Sie direkt zu unserem Online-Menü und Bestellen. Sie bekommen es in kürzester Zeit an Ihre Tür geliefert"/>
                    <meta name="dc.source" CONTENT=""/>
                    <meta name="dc.title" CONTENT="Speisekarte - Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310"/>
                    <meta name="DC.Language" content="de"/>
                    <meta property="og:locale" content="de_DE" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Speisekarte - Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310" />
                    <meta property="og:description" content="Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310, Erleben Sie authentische indische Küche und Tandoor Spezialitäten mit Liebe zubereitet. Geschmack bieten sich europäische Gerichte und auch asiatische Speisen. Gehen Sie direkt zu unserem Online-Menü und Bestellen. Sie bekommen es in kürzester Zeit an Ihre Tür geliefert" />
                    <meta property="og:url" content="https://www.foodboxragaz.ch/" />
                    <meta property="og:site_name" content="foodboxragaz.ch - Essen bestellen, Takeaway und Lieferung in Bad Ragaz 7310" />
                    <meta property="og:image" content="https://www.foodboxragaz.ch/foodboxragaz.jpg" />
                </Helmet>


            <MDBContainer className="image-container">
                <MDBRow>

                    {/* { now > goodFriday ? "" : 
                        <MDBCol md="12" className="mb-3">   
                            <MediaQuery maxWidth={760}>
                                <img src={image11Mob} className="img-fluid z-depth-1" alt="Karfreitagsmenü Speisen, Essen Bad Ragaz 7310, Lieferung Bad Ragaz 7310" />
                            </MediaQuery>
                        
                            <MediaQuery minWidth={760}> 
                                <img src={image11} className="img-fluid z-depth-1" alt="Karfreitagsmenü Speisen, Essen Bad Ragaz 7310, Lieferung Bad Ragaz 7310" />
                            </MediaQuery>
                        </MDBCol>
                    }

                    { now > easter ? "" : 
                        <MDBCol md="12" className="mb-3">   
                            <MediaQuery maxWidth={760}>
                                <img src={image10Mob} className="img-fluid z-depth-1" alt="Ostermenü Speisen, Essen Bad Ragaz 7310, Lieferung Bad Ragaz 7310" />
                            </MediaQuery>
                        
                            <MediaQuery minWidth={760}> 
                                <img src={image10} className="img-fluid z-depth-1" alt="Ostermenü Speisen, Essen Bad Ragaz 7310, Lieferung Bad Ragaz 7310" />
                            </MediaQuery>
                        </MDBCol>
                    } */}

                    <MDBCol md="12" className="mb-3">
                        <MediaQuery maxWidth={760}>
                            <img src={headerImageMob} className="img-fluid z-depth-1" alt="Essen, Indische Küche Bad Ragaz 7310, Takeaway Bad Ragaz 7310" />
                        </MediaQuery>
                    
                        <MediaQuery minWidth={760}> 
                            <img src={headerImage} className="img-fluid z-depth-1" alt="Essen, Indische Küche Bad Ragaz 7310, Takeaway Bad Ragaz 7310" />
                        </MediaQuery>                    
                    </MDBCol>



                    <MDBCol md="12" className="mb-3">
                        <MediaQuery maxWidth={760}>
                            <img src={menuImage1Mob} className="img-fluid z-depth-1" alt="Essen, Indische Küche Bad Ragaz 7310, Takeaway Bad Ragaz 7310" />
                        </MediaQuery>
                    
                        <MediaQuery minWidth={760}> 
                            <img src={menuImage1} className="img-fluid z-depth-1" alt="Essen, Indische Küche Bad Ragaz 7310, Takeaway Bad Ragaz 7310" />
                        </MediaQuery>                    
                    </MDBCol>

                    <MDBCol md="12" className="mb-3">
                        <MediaQuery maxWidth={760}>
                            <img src={menuImage2Mob} className="img-fluid z-depth-1" alt="Essen, Indische Küche Bad Ragaz 7310, Takeaway Bad Ragaz 7310" />
                        </MediaQuery>
                    
                        <MediaQuery minWidth={760}> 
                            <img src={menuImage2} className="img-fluid z-depth-1" alt="Essen, Indische Küche Bad Ragaz 7310, Takeaway Bad Ragaz 7310" />
                        </MediaQuery>                    
                    </MDBCol>

                    <MDBCol md="12" className="mb-3">
                        <MediaQuery maxWidth={760}>
                            <img src={menuImage3Mob} className="img-fluid z-depth-1" alt="Essen, Indische Küche Bad Ragaz 7310, Takeaway Bad Ragaz 7310" />
                        </MediaQuery>
                    
                        <MediaQuery minWidth={760}> 
                            <img src={menuImage3} className="img-fluid z-depth-1" alt="Essen, Indische Küche Bad Ragaz 7310, Takeaway Bad Ragaz 7310" />
                        </MediaQuery>                    
                    </MDBCol>

                    <MDBCol md="12" className="mb-3">
                        <MediaQuery maxWidth={760}>
                            <img src={menuImage4Mob} className="img-fluid z-depth-1" alt="Essen, Indische Küche Bad Ragaz 7310, Takeaway Bad Ragaz 7310" />
                        </MediaQuery>
                    
                        <MediaQuery minWidth={760}> 
                            <img src={menuImage4} className="img-fluid z-depth-1" alt="Essen, Indische Küche Bad Ragaz 7310, Takeaway Bad Ragaz 7310" />
                        </MediaQuery>                    
                    </MDBCol>

                    <MDBCol md="12" className="mb-3">
                        <MediaQuery maxWidth={760}>
                            <img src={menuImage5Mob} className="img-fluid z-depth-1" alt="Essen, Indische Küche Bad Ragaz 7310, Takeaway Bad Ragaz 7310" />
                        </MediaQuery>
                    
                        <MediaQuery minWidth={760}> 
                            <img src={menuImage5} className="img-fluid z-depth-1" alt="Essen, Indische Küche Bad Ragaz 7310, Takeaway Bad Ragaz 7310" />
                        </MediaQuery>                    
                    </MDBCol>


                </MDBRow>

            </MDBContainer>
        </main>

    );

}

export default Speisekarte;